





















































































import Vue from "vue";
import {mapActions, mapGetters} from "vuex";
import AddIcon from "@iconify-icons/ion/add-outline";
import Layout from "@/router/layouts/main.vue";
import SearchBar from "@/components/search-bar.vue";
import AudioSpotTableItem from "@/components/audio-commercials/AudioSpotTableItem.vue";
import AddAudioSpotModal from "@/components/modals/AddAudioSpotModal.vue";
import BackButton from "@/components/back-button.vue";
import { AdvertCampaignData, AudioSpotData } from "@/types/audio_commercials";
import { UserData } from "@/types";

export default Vue.extend({
  name: "list-of-audio-spots" as string,

  components: {
    AddAudioSpotModal,
    Layout,
    SearchBar,
    "audio-spot-table-item": AudioSpotTableItem,
    "add-audio-spot-modal": AddAudioSpotModal,
    "back-button": BackButton
  },

  data() {
    return {
      title: "List of Audio Spots",
      icons: {
        add: AddIcon,
      },
      currentPage: 1,
      perPage: 10,
      randomId: Math.floor(Math.random() * 100000),
      tableUpdated: false
    }
  },

  async mounted(): Promise<void> {
    await this.setAudioSpots();
    await this.fetchUsersImages()
    await this.setAdvertCampaigns({});
  },

  computed: {
    ...mapGetters("audioCommercialsModule", {
      audioSpots: "GET_AUDIO_SPOTS",
      advertCampaigns: "GET_ALL_ADVERT_CAMPAIGNS"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("userModule", {
      audioSpotsUsers: "GET_AUDIO_SPOTS_USERS"
    }),
    totalAudioSpots(): number {
      return this.audioSpots.length;
    },
    audioSpotsList(): Array<AudioSpotData> {
      return this.audioSpots.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
  },

  methods: {
    ...mapActions("audioCommercialsModule", {
      setAudioSpots: "FETCH_AUDIO_SPOTS",
      setAdvertCampaigns: "FETCH_ADVERT_CAMPAIGNS"
    }),
    ...mapActions("userModule", {
      setAudioSpotUsers: "FETCH_AUDIO_SPOTS_USERS"
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    selectedCompany() {
      this.$router.push("/select-company");
    },
    resetPagination(): void {
      this.currentPage = 1;
      this.fetchUsersImages()
    },
    isSpotDeletable(id: number){
      return !this.advertCampaigns.some(
        v => v.spots.some(s => s.id == id));
    },
    fetchUsersImages(): void {
      if(!this.audioSpots.length) return;
      for(const audioSpot of this.audioSpots){
        if(audioSpot.czone_user_image_last_updated != 0){
          this.setImage({id: audioSpot.czone_user_id, imageType: "clientzone_user", multi: true, venueQuestionaryImage: false, audioSpotImage: true});
        }
      }
    }
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setAudioSpots();
        }
      }
    }
  },

  beforeCreate() {
    document.body.className = "home";
  }
})
