






































import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "delete-item-confirm-modal" as string,

  props: {
    itemId: Number as PropType<number>,
    modalId: Number as PropType<number>,
    text: String as PropType<string>,
    removeAction: Function,
    isDeletable: {
      default: true,
      type: Boolean
    }
  },

  methods: {
    async confirmRemove(): Promise<void> {
      await this.removeAction(this.itemId);
      this.$bvModal.hide("delete-item-confirm-modal" + this.modalId);
    }
  }
})
