












































































































































































import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";
import {required} from "vuelidate/lib/validators";
import {AudioSpotData} from "@/types";
import {errorMessage} from "@/utils/messages-handlers";

export default Vue.extend({
  name: "add-audio-spot-modal" as string,

  props: {
    modalId: Number as PropType<number>,
    data: Object as PropType<AudioSpotData>,
    isEdit: Boolean as PropType<boolean>,
    campaigns: Array as PropType<Array<any>>
  },
  async mounted(): Promise<void> {
    if (this.isEdit === true) {
      this.audioSpot = await this.getSpotFile({id: this.data.id, streamType: "spot"});
    }
  },

  data() {
    return {
      spotName: this.isEdit ? this.data.name : "",
      textAuthor: this.isEdit ? this.data.text_author : "",
      musicAuthor: this.isEdit ? this.data.music_author : "",
      spotDescription: this.isEdit ? this.data.description : "",
      file: [],
      item: null,
      audioSpot: null,
      isSubmitted: false,
      supportedFormats: ['mpeg','ogg','wav','x-wav',"wav","aac","m4a","mp4"]
    }
  },

  //@ts-ignore
  validations: {
    spotName: { required },
    textAuthor: { required },
    musicAuthor: { required },
    audioSpot: { required },
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    ...mapGetters("audioCommercialsModule", {
      audioSpotLoader: "GET_AUDIO_SPOT_LOADER"
    }),
    formatFile(): string {
      return this.item.split(',')[1];
    },
    uploadedFileText(): string {
      if (!this.data) return this.$t('No file selected');
      if (this.data.file) return this.$t('');
      return this.$t('No file selected');
    }
  },

  methods: {
    ...mapActions("audioCommercialsModule", {
      addAudioSpot: "ADD_AUDIO_SPOT",
      editAudioSpot: "EDIT_AUDIO_SPOT",
    }),
    ...mapActions("globalModule", {
      getSpotFile: "GET_SPOT_FILE"
    }),
    previewFile(item, e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(item, files[0]);
    },
    createImage(item, file) {
      let image = new Image();
      let reader = new FileReader();
      let audioReader = new FileReader();

      reader.onload = (e) => {
        this.item = e.target.result;
      };
      reader.readAsDataURL(file);

      audioReader.onload = (e) => {
        let audio = file.type.split('/');
        if(!this.supportedFormats.includes(audio[1])) {
          this.audioSpot = [];
          errorMessage('Unsupported audio format',3000);
        }
        //@ts-ignore
        let audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContext.decodeAudioData(e.target.result, (buffer) => {
          let duration = buffer.duration;

          if(duration > 60){
            this.audioSpot = [];
            errorMessage("Audio spot is too long. Audio spot cannot exceed 30 seconds", 3000)
          }
      });
      };
      audioReader.readAsArrayBuffer(file);
    },

    async addSpot(): Promise<void> {
      this.isSubmitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const payload: object = {
        name: this.spotName,
        description: this.spotDescription,
        text_author: this.textAuthor,
        music_author: this.musicAuthor,
        venue_owner_id: this.companyId
      }

      if (this.item) payload["spot"] = this.formatFile;

      if (this.isEdit) {
        await this.editAudioSpot({payload: payload, id: this.data.id});
      } else {
        await this.addAudioSpot(payload);

        this.spotName = "";
        this.textAuthor = "";
        this.musicAuthor = "";
        this.spotDescription = "";
        this.item = null;
        this.audioSpot = null;
        this.isSubmitted = false;
      }
      this.$bvModal.hide('add-audio-spot' + this.modalId);
    }
  }
})
