


























































import Vue, {PropType} from "vue";
import { AdvertCampaignData, AudioSpotData } from "@/types/audio_commercials";
import {mapActions, mapGetters} from "vuex";
import AddAudioSpotModal from "@/components/modals/AddAudioSpotModal.vue";
import DeleteItemConfirmModal from "@/components/modals/DeleteItemConfirmModal.vue";
import { UserData } from "@/types";

export default Vue.extend({
  name: "audio-spot-table-item" as string,

  components: {
    "add-audio-spot-modal": AddAudioSpotModal,
    "delete-item-confirm-modal": DeleteItemConfirmModal
  },

  props: {
    data: Object as PropType<AudioSpotData>,
    isDeletable: Boolean as PropType<boolean>,
    advertCampaigns: Array as PropType<Array<AdvertCampaignData>>,
    audioSpotsUsers: Array as PropType<Array<UserData>>
  },

  data() {
    return {
      isPlaying: false,
      loader: false
    }
  },

  computed: {
    ...mapGetters("audioCommercialsModule", {
      audioSpots: "GET_AUDIO_SPOTS"
    }),
    insertSpotFile(): string {
      if (!this.data.file) return "";
      return `data:audio/wav;charset=utf-8;base64, ${this.data.file}`;
    },
    audioSpotDuration(): string {
      if (!this.data.duration) return "";
      return this.data.duration.substring(3, 8);
    },
    isAnySpotPlaying(): boolean {
      if (!this.audioSpots.length) return false;
      return !!this.audioSpots.filter(a => a.id !== this.data.id).find(s => s.canPlay);
    },
    filteredCampaignsContainingSpots(): Array<any> {
      const filteredCampaigns: Array<any> = [] as Array<any>;

      this.advertCampaigns.forEach((c: AdvertCampaignData) => {
        c.spots.forEach((i: AudioSpotData) => {
          if(i.id === this.data.id){
            filteredCampaigns.push(c)
          }
        })
      })
      return filteredCampaigns;
    },
    insertVenueImage(): string {
      if(!this.data.image) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${this.data.image}`
    }

  },

  methods: {
    ...mapActions("audioCommercialsModule", {
      deleteAudioSpot: "DELETE_AUDIO_SPOT",
      setCanPlayStatus: "SET_CAN_PLAY_STATUS"
    }),
    ...mapActions("globalModule", {
      setSpotFile: "FETCH_STREAM"
    }),
    async playAudioSpot(id: number): Promise<void> {
      if (!this.insertSpotFile) {
        if (!!this.data.spot_last_updated) {
          this.loader = true;
          await this.setSpotFile({id: this.data.id, streamType: "spot"});
        }
      } else {
        const el: any = document.getElementById('audio-player' + id);
        this.isPlaying = true;
        el.play();
        this.setCanPlayStatus({ id: this.data.id, state: true });
      }
    },
    pauseAudioSpot(id: number): void {
      const el: any = document.getElementById('audio-player' + id);
      this.isPlaying = false;
      el.pause();
      this.setCanPlayStatus({ id: this.data.id, state: false });
    }
  },
  watch: {
    isPlaying:{
      handler(value: boolean): void {
        this.$refs["audio"+this.data.id].addEventListener('timeupdate',(e) => {
          if (this.$refs["audio"+this.data.id].currentTime >= parseInt(this.data.duration.slice(-2))) {
            this.isPlaying = false;
            this.setCanPlayStatus({ id: this.data.id, state: false });
          }
        })
      }
    },
    insertSpotFile: {
      handler(value: string): void {
        if (value) {
          this.loader = false;

          setTimeout(() => {
            const el: any = document.getElementById('audio-player' + this.data.id);
            this.isPlaying = true;
            el.play();
          }, 0)
        }
      }
    },
    isAnySpotPlaying: {
      handler(value: boolean): void {
        if (value) {
          this.pauseAudioSpot(this.data.id);
        }
      }
    }
  }
})
